<template>
    <div class="dragtogenerate" :breadcrumb="breadcrumb">
        <Toolbar />
        <main>
            <!-- 左侧组件列表 -->
            <section class="left">
                <ComponentList />
                <RealTimeComponentList />
            </section>
            <!-- 中间画布 -->
            <section class="center">
                <div
                    class="content"
                    @drop="handleDrop"
                    @dragover="handleDragOver"
                    @mousedown="handleMouseDown"
                    @mouseup="deselectCurComponent"
                >
                    <Editor />
                </div>
            </section>
            <!-- 右侧属性列表 -->
            <section class="right">
                <el-tabs v-if="curComponent" v-model="activeName">
                    <el-tab-pane label="属性" name="attr">
                        <component :is="curComponent.component + 'Attr'"  style="height: 80vh;overflow: auto"/>
                    </el-tab-pane>
<!--                    <el-tab-pane label="动画" name="animation" style="padding-top: 20px;">-->
<!--                        <AnimationList />-->
<!--                    </el-tab-pane>-->
                    <el-tab-pane label="事件" name="events" style="padding-top: 20px;">
                        <EventList />
                    </el-tab-pane>
                </el-tabs>
                <CanvasAttr v-else></CanvasAttr>
            </section>
        </main>
    </div>
</template>

<script>
import Editor from '@/components/Editor/index' //编辑
import ComponentList from '@/components/ComponentList' // 左侧列表组件
import AnimationList from '@/components/AnimationList' // 右侧动画列表
import EventList from '@/components/EventList' // 右侧事件列表
import componentList from '@/custom-component/component-list' // 左侧列表数据
import Toolbar from '@/components/Toolbar'
import { deepCopy } from '@/utils/utils'
import { mapState } from 'vuex'
import generateID from '@/utils/generateID'
import { listenGlobalKeyDown } from '@/utils/shortcutKey'
import RealTimeComponentList from '@/components/RealTimeComponentList'
import CanvasAttr from '@/components/CanvasAttr'
import { changeComponentSizeWithScale } from '@/utils/changeComponentsSizeWithScale'
import { setDefaultcomponentData } from '@/store/snapshot'
import {getSceneTemplateListByCondition} from "A/jcgn";

export default {
  name:"DragToGenerate",
  components: {Editor, ComponentList, AnimationList, EventList, Toolbar, RealTimeComponentList, CanvasAttr},
  data() {
    return {
      breadcrumb: [
        ...this.$route.params.prePages,
      ],
      activeName: 'attr',
      reSelectAnimateIndex: undefined,
      monitorpointnum: '',
    }
  },
  computed: {
    ...mapState([
      'componentData',
      'curComponent',
      'isClickComponent',
      'canvasStyleData',
      'editor',
    ])
  },
  created() {
    this.monitorpointnum = this.$route.params.monitorpointnum?this.$route.params.monitorpointnum:"";
    this.restore()
    // 全局监听按键事件
    listenGlobalKeyDown()
  },
  methods: {
    restore() {
      // 用保存的数据恢复画布
      // if (localStorage.getItem('canvasData')) {
      //   setDefaultcomponentData(JSON.parse(localStorage.getItem('canvasData')))
      //   this.$store.commit('setComponentData', JSON.parse(localStorage.getItem('canvasData')))
      // }
      //
      // if (localStorage.getItem('canvasStyle')) {
      //   this.$store.commit('setCanvasStyle', JSON.parse(localStorage.getItem('canvasStyle')))
      // }
      this.getSceneTemplateList()

    },
    getSceneTemplateList(){
      getSceneTemplateListByCondition({scenetemplateid:this.$route.params.scenetemplateid}).then(res=>{
        if(res&&res.returncode=='0') {
          if (res.item[0].canvasdata) {
            setDefaultcomponentData(JSON.parse(res.item[0].canvasdata))
            this.$store.commit('setComponentData', JSON.parse(res.item[0].canvasdata))
          }else{
            if (localStorage.getItem('canvasData')) {
              setDefaultcomponentData(JSON.parse(localStorage.getItem('canvasData')))
              this.$store.commit('setComponentData', JSON.parse(localStorage.getItem('canvasData')))
            }
          }
          if (res.item[0].canvasstyle) {
            this.$store.commit('setCanvasStyle', JSON.parse(res.item[0].canvasstyle))
          }else{
            if (localStorage.getItem('canvasStyle')) {
              this.$store.commit('setCanvasStyle', JSON.parse(localStorage.getItem('canvasStyle')))
            }
          }
        }
      })
    },

    handleDrop(e) {
      e.preventDefault()
      e.stopPropagation()

      const index = e.dataTransfer.getData('index')
      const rectInfo = this.editor.getBoundingClientRect()
      if (index) {
        const component = deepCopy(componentList[index])
        component.style.top = e.clientY - rectInfo.y
        component.style.left = e.clientX - rectInfo.x
        component.id = generateID()

        // 根据画面比例修改组件样式比例 https://github.com/woai3c/visual-drag-demo/issues/91
        changeComponentSizeWithScale(component)

        this.$store.commit('addComponent', {component})
        this.$store.commit('recordSnapshot')
      }
    },

    handleDragOver(e) {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },

    handleMouseDown(e) {
      e.stopPropagation()
      this.$store.commit('setClickComponentStatus', false)
      this.$store.commit('setInEditorStatus', true)
    },

    deselectCurComponent(e) {
      if (!this.isClickComponent) {
        this.$store.commit('setCurComponent', {component: null, index: null})
      }

      // 0 左击 1 滚轮 2 右击
      if (e.button != 2) {
        this.$store.commit('hideContextMenu')
      }
    },
  },
}
</script>

<style lang="scss">
.dragtogenerate {
    height: 94vh;
    background: #fff;

    main {
        height: calc(100% - 100px);
        position: relative;

        .left {
            position: absolute;
            height: 100%;
            width: 200px;
            left: 0;
            top: 0;

            & > div {
                overflow: auto;

                &:first-child {
                    border-bottom: 1px solid #ddd;
                }
            }
        }

        .right {
            position: absolute;
            height: 100%;
            width: 270px;
            right: 0;
            top: 0;

            .el-select {
                width: 100%;
            }
        }

        .center {
            margin-left: 200px;
            margin-right: 275px;
            background: #f5f5f5;
            height: 100%;
            overflow: auto;
            padding: 10px;

            .content {
                width: 100%;
                height: 100%;
                overflow: auto;
            }
        }
    }

    .placeholder {
        text-align: center;
        color: #333;
    }

    .global-attr {
        padding: 10px;
    }
}
</style>
